.modal-content {
    direction: rtl;
}
.modal-footer button{
    background-color: rgba(89, 198, 201, 1);
    color: white;
    border: none;
    font-size: 1.2rem;

}
.modal-header {
    border: 0;
}
.modal-footer {
    border: 0;
}
.modal-footer button:active {
    background-color: rgba(89, 198, 201, 1);
    color: white;
}
.modal-footer button:hover {
    background-color: rgba(89, 198, 201, 1);
    color: white;
    border: none;
}
.modal-title {
    color : rgba(89, 198, 201, 1);
    font-size: 1.6rem;
}
.inputs-container label {
    color: rgba(23, 43, 77, 1);
}
.egp {
    position: relative;

}
.egp::after {
    content: 'EGP';
    position: absolute;
    left: 0;
    top: 0;
    color: black;
    background-color: red;
}
.user-value {
    color: rgba(165, 165, 165, 1);
}
.ticket::after {
    width: 137%;
}
.order-container  hr {
    color: #172b4d;
}