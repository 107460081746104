@media print {
  .navbar.navbar-expand-lg.navbar-light,
  footer,
  aside.px-4.py-3.d-none.d-md-flex.gap-3.flex-column,
  .mantine-Tabs-list {
    display: none !important;
  }
  .print-only {
    display: block !important;
    width: 100% !important;
  }
  .res-container {
    width: 100% !important;
  }
}
