.res-container {
  width: calc(100% - 250px) !important;
}

/* XXL and above screen sizes */
@media (min-width: 1650px) {
  /* .res-container {
    width: 100% !important;
  } */
}
@media (max-width: 774px) {
  .res-container {
    width: 100% !important;
  }
}
@media (max-width: 774px) {
  .menu-mobil-height {
    margin-bottom: 75px;
  }
}
  