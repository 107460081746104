/* static home  */
.accordion-button::after {
  margin-left: 0px !important;
}
.accordion-button {
  display: flex;
  justify-content: space-between;
}
.static-home-header {
  overflow: hidden;
  background-color: #ebfeff; /* Light cyan background */
  position: relative; /* To position the pseudo-elements */
}

.static-home-header::before {
  content: "";
  position: absolute;
  top: -50px;
  left: -100px;
  width: 300px;
  height: 300px;
  background-color: #ffe4c4; /* Light beige */
  border-radius: 50%; /* Makes it circular */
  z-index: 1;
}

.static-home-header::after {
  content: "";
  position: absolute;
  bottom: -100px;
  right: -50px;
  width: 400px;
  height: 400px;
  background-color: #ffe4c4; /* Light beige */
  border-radius: 50%; /* Makes it circular */
  z-index: 1;
}

.carousel-item {
  min-height: 50vh;
  position: relative;
  z-index: 2; /* Ensures carousel content is above the shapes */
}

.carousel-item-md {
  height: 60vh;
  position: relative;
  z-index: 2; /* Ensures content is above the shapes */
}

.carousel-img-wrapper {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: center;
}
.carousel-img1 {
  height: 490px;
  width: 440px;
}
.carousel-img2 {
  right: -75px;
  height: 20%;
  bottom: 50px;
}
.carousel-img3 {
  top: 80px;
  left: 28px;
  height: 17%;
}
.carousel-img4 {
  height: 400px;
  background: #ffe4c4;
  border-radius: 100%;
}
@media (min-width: 1200px) {
  .carousel-img1 {
    height: 550px;
    width: 500px;
  }
  .carousel-img2 {
    right: -90px;
    height: 20%;
    bottom: 50px;
  }
  .carousel-img3 {
    top: 80px;
    left: 28px;
    height: 17%;
  }
  .static-home-header-title {
    font-size: 50px;
  }
  .static-home-header-description {
    font-size: 25px;
  }
}

@media (max-width: 992px) {
  .carousel-img1 {
    width: 440px;
    height: 375px;
  }
  .carousel-img2 {
    right: -80px;
    height: 20%;
    bottom: -35px;
  }
  .carousel-img3 {
    top: 80px;
    height: 17%;
    left: -30px;
  }
  .static-home-header-title {
    font-size: 40px;
  }
  .static-home-header-description {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .static-home-header::before {
    top: -30px;
    left: -50px;
    width: 200px;
    height: 200px;
  }

  .static-home-header::after {
    bottom: -50px;
    right: -30px;
    width: 250px;
    height: 250px;
  }
  .carousel-img1 {
    height: 220px;
    width: 220px;
  }
  .carousel-img2 {
    right: -60px;
    height: 30%;
    bottom: 5px;
  }
  .carousel-img3 {
    height: 25%;
    top: 25px;
  }
  .carousel-img4 {
    height: 350px;
    background: transparent;
    border-radius: 0%;
  }
  .static-home-header-title {
    font-size: 30px;
  }
  .static-home-header-description {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .static-home-header::before {
    top: -30px;
    left: -20px;
    width: 150px;
    height: 150px;
  }

  .static-home-header::after {
    bottom: -60px;
    right: -10px;
    width: 200px;
    height: 200px;
  }
  .carousel-img1 {
    height: 150px;
    width: 150px;
  }
  .carousel-img2 {
    right: -40px;
    height: 25%;
    bottom: 10px;
  }
  .carousel-img3 {
    height: 20%;
    top: 15px;
    left: -20px;
  }
  .carousel-img4 {
    height: 250px;
    background: transparent;
    border-radius: 0%;
  }
  .static-home-header-title {
    font-size: 25px;
  }
  .static-home-header-description {
    font-size: 18px;
  }
}
