/* Custom styles for react-date-range */
.rdrSelected,
.rdrStartEdge,
.rdrEndEdge {
  background-color: rgba(89, 198, 201, 1) !important;
  color: #fff !important;
}

.rdrInRange {
  background-color: rgba(89, 198, 201, 1) !important;
}

.rdrButton {
  transition: all 0.3s ease;
}

.rdrDayNumber:hover {
  background-color: transparent !important;
  border-color: rgba(89, 198, 201, 1) !important;
  color: rgba(89, 198, 201, 1) !important;
  border-width: 2px !important;
  border-style: solid !important;
}

.container-position {
  width: 97%;
  position: absolute;
  z-index: 15;
  overflow: auto;
  right: 10px;
}

@media (min-width: 1400px) {
  .container-position {
    width: 80.20%;
    position: absolute;
    z-index: 15;
    overflow: auto;
    right: 215px;
  }
}