.add-product-container {
    direction: rtl;
}
.add-product-container .add-header h2 {
    color: rgba(243, 168, 55, 1);
}
.add-product-container .add-header p {
    color: rgba(165, 165, 165, 1);
}
.add-body1 .right-side {
    flex-grow: 2;
}
.add-body1 .left-side{
    flex-grow: 1;
}

.left-side {
    border: 1px solid rgba(239, 237, 237, 1);
    border-radius: 7px;
}
.left-side .top {
    background-color: rgba(89, 198, 201, 1);
    width: 100%;
    color: white;
    border-radius:7px 7px 0px 0px;
}
.left-side > button {
    color: rgba(89, 198, 201, 1);
    border: 1px solid rgba(89, 198, 201, 1);
    font-size: 1.2rem;
}
.left-side > button:hover {
    background-color: rgba(89, 198, 201, 1);
    color: white;
}
.left-body {
    color: rgba(165, 165, 165, 1);
}
.right-head h3 {
    color: rgba(23, 43, 77, 1);
}
.right-head p {
    color: rgba(165, 165, 165, 1);
}
.right-side label {
    font-size: 1.19rem;
}
.sku_info *{
    margin: 0 !important;
}
.price-row {
    border-top: 1px solid rgba(239, 237, 237, 1);
}
.price-row:first-child {
    border-top: none;
}
.price-row p{
    margin: 0;
} 
.price-row:last-child {
    border-top: 1px solid rgba(23, 43, 77, 1);
}
.price-info-cart p:first-child {
    font-size: 1.2rem;
}
.price-info-cart p  {
    margin: 0;
}
.price-info-cart p {
    text-align: center;
}
