.profile-container {
    text-align: right;
    border: 1px solid rgba(239, 237, 237, 1);
    border-radius: 15px;
}
.profile-container  > button{
    color: white;
    background-color: rgba(89, 198, 201, 1);
}
.profile-container  > button:hover{
    color: white;
    background-color: rgba(89, 198, 201, 1);
}
.profile-container .user-info {
    direction: rtl;
    flex-grow: 1;
    font-size: 1.2rem;
}

.profile-container .user-info .user-key {
    color: black;
}
.profile-container .user-info .user-value {
    color: rgba(165, 165, 165, 1);
}
.profile-container .user-info .user-value button {
    position: absolute;height: 75%;background-color: rgba(89, 198, 201, 1);top:50%;translate: 0 -50%;color: white;left: 0;
    align-items: center;
    font-size: .77rem;
}
.ticket {
    background-color: rgba(243, 168, 55, 1);
    color: white;
    text-align: center;
    position: relative;
    border-radius: 5px;
}
.ticket p {
    padding-bottom: 40px;
}
.ticket::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-bottom: 50.5px solid white;
    /* top: -100%; */
    bottom: 0;
    left: 50%;
    translate: -50% 0;

}
.lower .box {
    background-color: rgba(235, 254, 255, 1);
    border-radius: 15px;
    flex-grow: 1;
}
.lower .box .number {
    font-size: 1.7rem;
    color: rgba(89, 198, 201, 1);
}
.lower .box.orangify {
    background-color: rgba(255, 235, 205, 1);
}
.lower .box.orangify .number {
    color: rgba(243, 168, 55, 1);

}
.lower .box h2 {
    font-size: 1.4rem;
    color: rgba(23, 43, 77, 1);
}
.footer .header h2{
    color: rgba(243, 168, 55, 1);
}
.inputs-group > div {
    flex-grow: 1;
}
.inputs-group label {
    font-size: 1.4rem;
}
.inputs-group input {
    padding-block: .8rem;
}

@media (max-width:770px) {
    .upload img {
        width: 26px;
    }
    .profile-container .user-info {
        font-size: 1rem;
    }
}
@media (max-width:430px) {
    .lower .box .number {
        font-size: 1.3rem;
    }
    .lower .box h2 {
        font-size: 1.4rem;
    }
    .profile-container .user-info {
        font-size: .7rem;
    }

    .profile-container .user-info .user-value button {
        font-size: .7rem;
        height: 50%;
    }
    .profile-container .user-info .user-value input {
        font-size: .5rem;
    }
    .profile-container .user-info .user-value img {
        width: 10px;
    }

}
.inputs-group > button:first-child {
    background-color: rgba(89, 198, 201, 1);
    color: white;
    padding: .7rem;
}
.inputs-group > button:last-child {
    border: 1px solid rgba(89, 198, 201, 1);
    color : rgba(89, 198, 201, 1);
    padding: .7rem;
}
.perm-container > div {
    border: 1px solid #EFEDED;
    padding-block: 1.2rem;
    border-radius: 10px;
}
.perm-container  button:last-child {
    background-color:  rgba(89, 198, 201, 1);
    color : white;
    padding: .3rem;
}