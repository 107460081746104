aside .feature_link p {
  margin: 0;
  color: rgba(23, 43, 77, 1);
  font-size: 1.4rem;
}
aside .feature_link:hover {
  background-color: rgba(89, 198, 201, 1);
  padding-right: 0.7rem;
  cursor: pointer;
}
aside .feature_link_en:hover {
  background-color: rgba(89, 198, 201, 1);
  padding-left: 0.7rem;
  cursor: pointer;

}

aside .feature_link:hover p {
  color: white;
}

aside .feature_link a {
  text-decoration: none;
  color: rgba(23, 43, 77, 1);
}
aside .feature_link:hover a {
  color: white;
}
aside {
  position: sticky;
  top: 0;
}
.feature_link {
  text-decoration: none;
  margin: 0px 15px;
}
.feature_link + ul {
  display: none;
}
.feature_link.active + ul {
  transition: display 4s;
}
.feature_link.active + ul {
  display: flex;
} 
.sub-nav {
  display: none;
}

.feature_link.active + .sub-nav {
  display: flex;
} 

/* .feature_link.active {
  background-color: rgba(89, 198, 201, 1);
  padding-right: 0.7rem;
} */
 
/* .links-container:hover  ul {
  display: flex;
} */
 .active-link{
  background-color: rgba(89, 198, 201, 1);
  padding-right: 0.7rem; 
  color: white;
}
 
.feature_link.active{
  background-color: rgba(89, 198, 201, 1);
  padding-right: 0.7rem; 
  color: white;
}
.feature_link.active p {
  color: white !important;
}

.feature_link_en.active{
  background-color: rgba(89, 198, 201, 1);
  padding-right: 0.7rem; 
  color: white;
}
.feature_link_en.active p {
  color: white !important;
}
.feature_link_en.active path {
  fill: #fff !important;
}
  .active-link path {
  fill: #fff !important;
}
.feature_link.active path {
  fill: #fff !important;
}
aside .feature_link:hover path {
  fill: white;
}


.active-link p {
  color: white !important;
}

aside .feature_link {
  margin: 0;
  /* border-bottom: 1px solid rgba(239, 237, 237, 1); */
  transition: all 0.3s;
  border-radius: 15px;
}
aside .feature_link:last-child {
  border-bottom: none;
}
aside {
  border: 1px solid rgba(239, 237, 237, 1);
  height: 100%;
  border-radius: 7px;
  width: 300px;
}
.offcanvas-body aside {
  border: 1px solid rgba(239, 237, 237, 1);
  height: 125%;
  border-radius: 7px;
  max-width: 100%;
  min-width: 100%;
  height: fit-content;
}
.active path {
  fill: "#eee";
}
.links-container {
  border-top: 1px solid rgba(239, 237, 237, 1);
}
.links-container:first-child {
  border-top: none;
}
.sub-nav a.active {
  color: rgb(243, 168, 55);
  text-decoration: none;
}
.sub-nav a {
  text-decoration: none;
  color: rgba(23, 43, 77, 1);
}
.icon{
  height: 23px;
  width: 28px;
  color: rgba(89, 198, 201, 1) !important;
}
 
 


