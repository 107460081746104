.box1 {
  background-color: hsl(182, 100%, 96%);
}

.box2 {
  background-color: #ffebcd;
}

.box3 {
  background-color: hsl(243, 100%, 96%);
}
