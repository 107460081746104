:root {
  --back-ground: #ebfeff;
  --primary-color: #59c6c9;
  --text: #172b4d;
  --secondary-color: #f3a837;
  --accent-color: #e74c3c;
  --background-color: #ecf0f1;
  --text-color: #333;
}
@font-face {
  font-family: "Din arabic"; /* Choose a name for your font */
  src: url("./fonts/DINNextLTArabic-Regular.ttf") format("truetype");
  /* You might include additional font formats here, depending on browser support */
}
body {
  color: rgb(23, 43, 77);
}
* {
  margin: 0;
  padding: 0;
  font-family: "Din arabic";
}
.filepond--action-process-item {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
span,
p {
  font-family: "Din arabic";
  font-weight: 500;
}
.daterangepicker .ranges li.active {
  background-color: rgba(89, 198, 201, 1);
}
*::selection {
  background-color: #59c6c9;
  color: white;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
svg {
  cursor: pointer;
}
.loader svg {
  animation: rotate 0.8s linear infinite;
}
footer {
  background-color: rgba(23, 43, 77, 1);
}
.text-ecombo {
  color: rgba(23, 43, 77, 1);
}
footer .section a {
  text-decoration: none;
  color: white;
  font-size: 1.4rem;
  font-weight: 100;
}
svg path {
  transition: fill 0.4s;
}
svg:hover path.navico {
  fill: rgb(243, 168, 55);
}
button.catHoverStyle {
  background-color: rgba(89, 198, 201, 1);
  color: white;
}
.card {
  border-radius: 15px;
  color: rgba(23, 43, 77, 1);
  transition: all 0.2s;
}
.card .title {
  font-size: 1.3rem;
}
.card .rating {
  font-size: 1rem;
}
.heart {
  width: 2.2rem;
}
.card-body button {
  background-color: rgba(89, 198, 201, 1);
  color: white;
}
.card-body button:hover {
  color: white;
}

img {
  transition: 0.3s; /* Add a smooth transition */
}

img:hover {
  filter: brightness(98%); /* Apply a brightness filter on hover */
}
svg.heartCart {
  transition: fill 0.4s;
}
svg.heartCart:hover {
  fill: rgba(89, 198, 201, 1);
}
svg.heartCart:hover path {
  stroke: white;
}
svg.heartCart.active {
  fill: rgba(89, 198, 201, 1);
}
svg.heartCart.active path {
  stroke: white;
}
.page-item span,
.page-item a {
  /* border: none; */
  outline: none;
  color: rgb(243, 168, 55);
}
.page-link:hover:not(.page-item.active) {
  color: rgb(243, 168, 55);
}
.page-link:focus {
  color: rgb(243, 168, 55);
}
.page-item.active span {
  background-color: rgb(243, 168, 55);
  border-color: rgb(243, 168, 55);
}
.drp-buttons .applyBtn {
  background-color: rgba(89, 198, 201, 1);
  border: none;
}
button {
  /* background-color: rgba(89, 198, 201, 1) !important; */
}
.phone_footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.box-phone:not(.active) svg path {
  fill: #a5a5a5;
}
.box-phone:not(.active) p {
  color: #a5a5a5;
}
.phone_footer a {
  color: #172b4d;
}
.filepond--credits {
  display: none !important;
}
.filepond--wrapper {
  width: 100%;
}
.ticket-box:hover {
  box-shadow: "0 0 11px rgba(33,33,33,.2)";
}
.dropdown-item {
  display: flex;
}
.dropdown-item * {
  width: 100%;
}
.dropdown-item:hover {
  background-color: rgba(89, 198, 201, 1);
}
.dropdown-item:hover * {
  color: white !important;
}
.rdw-dropdown-selectedtext {
  text-decoration: none;
  color: rgb(243, 168, 55);
}
.rdw-editor-main {
  overflow: auto;
  box-sizing: border-box;
  height: 200px;
  border: 2.5px solid #eee;
  border-radius: 10px;
}
.btn-close {
  margin: 0 !important;
}
textarea:focus,
select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.form-select:focus,
.input-group > .form-control:focus,
.uneditable-input:focus {
  border-color: rgba(89, 198, 201, 1) !important;
  box-shadow: 0 0 4px rgba(89, 198, 201, 1) !important;
  outline: 0 none !important;
}
button:hover {
  color: white;
  background-color: rgba(89, 198, 201, 1);
}
@media (max-width: 500px) {
  #root {
    overflow-x: "hidden";
  }
}
a.cat-link {
  transition: all 0.4s;
}
a.cat-link:hover {
  background-color: rgba(89, 198, 201, 1) !important;
  color: white !important;
}
button.p-image-preview-indicator {
  color: white;
  background-color: rgba(89, 198, 201, 0.5);
}
.ql-image {
  display: none !important;
}
.product-description * {
  max-width: 100%;
  /* direction: rtl; */
}
.p-galleria-thumbnail-item-content {
  overflow: hidden;
}

/* new style */
table tr:nth-child(odd) {
  background-color: #f9f9f9 !important;
}
td {
  background-color: transparent !important;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }
}

@media (min-width: 770px) {
  .cart-stat-width {
    max-width: 37%;
  }
}
#custom-switch {
  border: 1px solid rgb(89, 198, 201);
  width: 50px;
  height: 25px;
}
.direction-ltr {
  direction: ltr;
}
.ecombo-btn {
  background-color: rgb(89, 198, 201) !important;
  border: none;
  color: white !important;
}
.ecombo-btn:hover {
  background-color: rgb(89, 198, 201) !important;
  border: none;
}
.ecombo-btn-secondary {
  background-color: rgb(243, 168, 55) !important;
  border: 1px solid rgb(243, 168, 55);
  color: white !important;
}
.ecombo-btn-secondary:hover {
  background-color: transparent !important;
  color: rgb(89, 198, 201) !important;
  border: 1px solid rgb(89, 198, 201);
}
.ecombo-primary {
  color: rgb(89, 198, 201) !important;
}
.ecombo-secondary {
  color: rgb(243, 168, 55) !important;
}
.ecombo-link-primary {
  color: rgb(89, 198, 201) !important;
}
.ecombo-link-secondary {
  color: rgb(243, 168, 55) !important;
}
.ecombo-select-active {
  border: 1px solid #7ef5a0;
  color: #7ef5a0;
}
.ecombo-select-inactive {
  border: 1px solid #ff0000;
  color: #ff0000;
}
.ecombo-border {
  border: 1px solid rgb(243, 168, 55);
}

.row-danger {
  background-color: red !important;
  background: red !important;
}
tr.row-danger:nth-child(odd) {
  background-color: red !important;
  background: red !important;
}
td.cell-danger {
  background-color: #ffcccc !important; /* Light red background */
}
.modal-header {
  display: flex;
  justify-content: space-between;
}
.offcanvas-header {
  display: flex;
  justify-content: space-between;
}
/* styles.css */
.slider-padding {
  padding: 0px;
}

@media (min-width: 768px) {
  .slider-padding {
    padding: 30px;
  }
}

.carousel-item{
  min-height: 0px !important;
}