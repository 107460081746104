.p-checkbox.p-highlight .p-checkbox-box {
  border-color: rgb(89, 198, 201);
  background: rgb(89, 198, 201);
}
.ant-image {
  height: 100%;
  min-width: 100%;
  border-radius: 14px;
}
.trash {
  top: 0;
  right: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  justify-content: center;
}
.thumb {
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  justify-content: center;
}
.antd-container {
  border: 1px solid rgba(239, 237, 237, 1);
}
