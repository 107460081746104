.btn-close {
    --bs-btn-close-bg : url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwcHgiIGhlaWdodD0iMTcwcHgiIHZpZXdCb3g9IjAgMCA3NiA3NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM4LjAwMjYgNjkuNjY2N0M1NS40MTkzIDY5LjY2NjcgNjkuNjY5MyA1NS40MTY3IDY5LjY2OTMgMzhDNjkuNjY5MyAyMC41ODMzIDU1LjQxOTMgNi4zMzMzNCAzOC4wMDI2IDYuMzMzMzRDMjAuNTg1OSA2LjMzMzM0IDYuMzM1OTQgMjAuNTgzMyA2LjMzNTk0IDM4QzYuMzM1OTQgNTUuNDE2NyAyMC41ODU5IDY5LjY2NjcgMzguMDAyNiA2OS42NjY3WiIgc3Ryb2tlPSIjRkYwQjBCIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjkuMDM5MSA0Ni45NjE3TDQ2Ljk2MjQgMjkuMDM4MyIgc3Ryb2tlPSIjRkYwQjBCIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNDYuOTYyNCA0Ni45NjE3TDI5LjAzOTEgMjkuMDM4MyIgc3Ryb2tlPSIjRkYwQjBCIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K') !important;
    background-size: cover !important;
}
.heading {
    font-size: 20px !important;
  }
  .under {
    font-size: 15px !important;
  }
  .cart-card .img-container img {
    width: 6rem;
  }
  .offcanvas {
    width: auto !important;
    min-width: 300px;
  }