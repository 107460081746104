.stats-component .report-box {
  background-color: rgba(235, 254, 255, 1);
  text-align: center;
  border-radius: 7px;
  transition: all 0.3s;
}
.stats-component .report-box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.stats-component .report-box.orangify {
  background-color: rgba(255, 235, 205, 1);
  text-align: center;
  border-radius: 7px;
}
.stats-component .report-box .top p {
  font-size: 1.2rem;
}
@media (max-width: 770px) {
  .stats-component .report-box .top p {
    font-size: 0.8rem;
  }
  .interval {
    align-items: flex-end;
  }
  .interval > * {
    flex-grow: 1;
  }
  .reports-header {
    align-items: flex-end;
  }
  .reports-header > * {
    flex-grow: 1;
  }
}
.stats-component .report-box .top img {
  border: 10px solid rgba(225, 254, 255, 1);
  background-color: rgba(225, 254, 255, 1);
  border-radius: 100%;
  margin: 0;
  padding: 0;
}
.stats-component .report-box.orangify .top img {
  border: 10px solid rgba(255, 217, 159, 1);
  background-color: rgba(255, 217, 159, 1);
}
.stats-component .report-box .footer p {
  color: rgba(89, 198, 201, 1);
}
.stats-component .report-box.orangify .footer p {
  color: rgba(243, 168, 55, 1);
}
.stats-component tbody tr {
  border: 1.3px solid rgba(239, 237, 237, 1);
  border-radius: 7px;
}
.stats-component thead tr {
  color: rgba(165, 165, 165, 1);
  font-size: 0.8rem;
}
.stats-component tbody tr td,
tbody tr th {
  font-size: 0.84rem;
}
table th {
  padding: 5px 10px !important;
}
.reports-table * {
  font-size: 1.1rem;
}
