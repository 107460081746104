div .cover-left {
    background: linear-gradient(180deg, #59C6C9 0%, rgba(143, 227, 230, 0.8) 62.97%);
    position: relative;    
}
.inputs-data label {
    color: rgba(23, 43, 77, 1);
}
.inputs-data a {
    color: rgba(243, 168, 55, 1);
}
@media (min-width:720px) {
    .vh-for-high {
        min-height: 100vh;
    }
    .verify {
        max-width: 37%;
    }
}
