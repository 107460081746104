p {
    margin: 0;
}
.box {
    border: 1px solid rgba(239, 237, 237, 1);
    border-radius: 7px;
    transition: all .2s;
}
.box:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}
.stats-container {
    direction: rtl;
}
.stats-container .head p {
    font-size: 1.23rem;
}
.stats-container .head img {
    border:10px solid;
    border-radius: 100%;
}
.stats-container .number {
    text-align: center;
}
.stats-container .number p {
    font-size: 1.8rem;
}
.stats-container .foot p {
    font-size: .9rem;
}
.column-charts .top {
    height: 500px;
    background-color: transparent;
}
.column-charts .top .column {
    background-color: rgba(211, 243, 244, 1);
    /* flex-grow: 1; */
    width: 20px;
    border-radius: 10px;
}
.column-charts .top .column.active {
    background-color: rgba(89, 198, 201, 1);
}
.pie-chart {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  
  .slice {
    position: absolute;
    width: 100%;
    height: 100%;
    clip: rect(0, 100px, 200px, 0);
    border-radius: 50%;
    background-color: #ccc; /* Default color */
    transform: rotate(0deg);
  }
  
  .slice::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    clip: rect(0, 100px, 200px, 100px);
    background-color: inherit;
    transform: rotate(var(--percentage));
  }
.green-box, .yellow-box, .ben-box {
    width: 70px;
    height: 70px;
    background-color: red;
    border-radius: 7px;
}
.info-container .text-container {
    color: rgba(23, 43, 77, 1);
}
.piechart-container {
    border: 1px solid rgba(239, 237, 237, 1);
    border-radius: 7px;
}
.stats-flex > div {
    border: 1px solid #EFEDED;
    border-radius: 7px;
}
.start-date, .end-date {
    background-color: rgba(89, 198, 201, 1)!important;
}
.top-calendar-input input{
padding: .5rem 1rem;
}