.money-card {
  background: rgba(235, 254, 255, 1);
  transition: all 0.3;
  color: rgba(23, 43, 77, 1);
  border-radius: 13px;
  font-size: 18px;
}
.money-card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.money-card .money {
  font-size: 22px;
  font-weight: bold;
}
.money-card img {
  background-color: rgba(206, 251, 253, 1);
  border-radius: 50%;
  height: 100%;
  padding: 7px;
  height: 50px;
  width: 50px;
}
.money-card.orangify {
  background: rgba(255, 235, 205, 1);
}
.money-card.orangify img {
  background-color: rgba(255, 217, 159, 1);
  color: rgba(255, 217, 159, 1);
}
.withdrawal button {
  width: 100%;
  background-color: rgba(89, 198, 201, 1);
  font-size: 18px;
  color: white;
  font-weight: bold;
}
.withdrawal button:hover {
  color: white;
  background-color: rgba(89, 198, 201, 1);
}
.wallet-container .table {
  direction: rtl;
  color: rgba(165, 165, 165, 1);
  border: 0;
  text-align: center;
  overflow: scroll;
}
.wallet-container .table-responsive {
  border: 1px solid rgba(239, 237, 237, 1);
  border-radius: 40px;
}
.wallet-container .table tr td {
  padding-block: 20px;
}
.wallet-container .table th {
  color: rgba(23, 43, 77, 1);
  border-bottom: rgba(239, 237, 237, 1) 1px solid;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.table .status {
  color: rgba(1, 200, 92, 1);
  border: 1px solid rgba(1, 200, 92, 1);
  border-radius: 7px;
}
