.p-multiselect-items {
    padding: 0px 10px !important;
    direction: rtl;
}
.p-multiselect-item {
    padding: 5px 5px !important;
}
.p-multiselect-item {
    padding: 5px 5px !important;
    margin: 5px 0px;
    direction: rtl;
    gap: 5px;
}
.p-multiselect-header {
    display: none !important;
}