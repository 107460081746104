.m-87cf2631 {
    transition: all .3s;
}
.m-87cf2631:hover {
    background-color: #eee;
}
.table .status {
    color: rgba(1, 200, 92, 1);
    border: 1px solid rgba(1, 200, 92, 1);
    border-radius: 7px;
}
.tables-container .table {
    color: rgba(165, 165, 165, 1);
    border: 0;
    text-align: center;
    overflow: scroll;
}
.tables-container .table-responsive {
    border: 1px solid rgba(239, 237, 237, 1);
    border-radius: 40px;
}
.tables-container .table tr td {
    padding-top: 20px;

}
.table-responsive {
    width: 100%;
}
.tables-container .table th {
    color: rgba(23, 43, 77, 1);
    border-bottom: rgba(239, 237, 237, 1) 1px solid !important;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom-color : none;
}
.form-check-input:checked {
    background-color: rgba(243, 168, 55, 1) !important;
}
tbody tr  {
    border-bottom: 1px solid rgba(239, 237, 237, 1);
    
}
table tr{
    /* display: flex; */
    /* flex-direction: ; */
}
@media (max-width:770px) {
    .upload img {
        width: 26px;
    }
    .profile-container .user-info {
        font-size: 1rem;
    }
}
@media (max-width:430px) {
    .lower .box .number {
        font-size: 1.3rem;
    }
    .lower .box h2 {
        font-size: 1.4rem;
    }
    .profile-container .user-info {
        font-size: .7rem;
    }

    .profile-container .user-info .user-value button {
        font-size: .7rem;
        height: 50%;
    }
    .profile-container .user-info .user-value input {
        font-size: .5rem;
    }
    .profile-container .user-info .user-value img {
        width: 10px;
    }

}
