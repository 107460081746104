.product-info p {
  /* white-space: nowrap; */
}
.product-info {
  border: 1px solid rgba(239, 237, 237, 1);
  border-radius: 7px;
  direction: rtl;
}
.product-info .product-gallery {
  position: relative;
  height: 100%;
}
.top h1 {
  color: rgba(23, 43, 77, 1);
  font-size: 2rem;
}
.product-label {
  color: rgba(165, 165, 165, 1);
  font-size: 1.2rem;
}
.product-value {
  color: rgba(23, 43, 77, 1);
  font-size: 1.2rem;
}
.price {
  color: rgba(243, 168, 55, 1);
  font-size: 1.2rem;
}
.suggest {
  color: rgba(89, 198, 201, 1);
  font-size: 1.2rem;
}
.product-description {
  color: #0f1933;
  text-align: right;
  font-size: 0.97rem;
}
.buttons .top button:first-child {
  background-color: rgba(89, 198, 201, 1);
  color: white;
  font-size: 1.2rem;
}
/* .buttons .top button:last-child {
    border: 1px solid rgb(236, 245, 246);
    color: rgba(89, 198, 201, 1);
    font-size: 1.2rem;
} */
.buttons .bottom button {
  color: rgba(23, 43, 77, 1);
  border: 1.4px solid rgba(89, 198, 201, 1);
  font-size: 1.2rem;
}

@media (max-width: 770px) {
  .product-container {
    flex-direction: column-reverse;
  }
  .product-container .product-description,
  .product-container .buttons {
    display: none !important;
  }
  .mobile .product-description,
  .mobile .buttons {
    display: block !important;
  }
  .top h1 {
    font-size: 1.2rem;
  }

  .product-label {
    font-size: 0.9rem;
  }
  .product-value {
    font-size: 0.9rem;
  }
  .price p {
    white-space: normal;
  }
  .price {
    font-size: 1rem;
  }
  .suggest {
    font-size: 1rem;
  }
  .suggest p {
    white-space: normal;
  }
  .mobile {
    display: block;
  }
  .product-info {
    border: none;
  }
  .product-info .top img {
    display: none;
  }
  .show {
    display: block;
  }
}
@media (min-width: 770px) {
  .mobile {
    display: none;
  }
}
@media (min-width: 1200px) {
  .product-info {
    justify-content: center;
  }
  .product-description {
    line-height: 1;
  }
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
/* Swiper */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  border-radius: 7px;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1;
}
.mySwiper2 .swiper-slide-thumb-active {
  width: 546px !important;
}
.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  width: 145.333px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}
/* swiper */

.card {
  border-radius: 15px;
  color: rgba(23, 43, 77, 1);
  transition: all 0.2s;
}
.card .title {
  font-size: 1.3rem;
}
.card .rating {
  font-size: 1rem;
}
.heart {
  width: 2.2rem;
}
.card-body button {
  background-color: rgba(89, 198, 201, 1);
  color: white;
}
.card-body button:hover {
  color: white;
}
.price_bundles {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 2px rgba(96, 60, 182, 1),
    0px 0px 0px 8px rgba(255, 150, 71, 0.07),
    0px 5px 10px 0px rgba(227, 227, 238, 1);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 18px;
  padding-left: 18px;
}
.bundle {
  border-radius: 8px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  box-shadow: 0 0 0 2px rgba(128, 128, 128, 0.681);
}
.bundle.active {
  box-shadow: 0 0 0 2px var(--color-light-blue);
}

.best-offer {
  position: absolute;
  right: 20px;
  top: -8px;
  background-color: var(--secondary-color);
  padding: 6px 12px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  width: 90px;
  text-transform: uppercase;
  text-align: center;
  z-index: 10;
}

.best-offer span {
  position: absolute;
  width: 0;
  height: 0;
  border-bottom-width: 8px;
  border-bottom-style: solid;
  display: block;
  top: 0;
  filter: brightness(0.7);
}

.best-offer span.right {
  right: 0;
  transform: translateX(100%);
  border-right: 8px solid rgba(0, 0, 0, 0);
}

.best-offer span.left {
  left: 0;
  transform: translateX(-100%);
  border-left: 8px solid rgba(0, 0, 0, 0);
}
