.success-container {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.success-container > * {
  width: 100%;
}
.success-container > button {
  background-color: rgba(89, 198, 201, 1);
  font-weight: bold;
}
.success-container a {
  color: white;
}
.success-container .success-header {
  color: rgba(243, 168, 55, 1);
  font-size: 2erm;
  text-align: center;
}
.success-body .img {
  width: 100%;
}
.success-body .img img {
  width: 10rem;
}
.success-body .btns-group button:first-child {
  border: 1px solid rgba(243, 168, 55, 1);
  color: rgba(243, 168, 55, 1);
  font-size: 1.2rem;
  font-weight: bold;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.success-body .btns-group button:last-child {
  background-color: rgba(243, 168, 55, 1);
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.success-body .btns-group a:first-child {
  color: rgba(243, 168, 55, 1);

  display: block;
  text-decoration: none;
}
.success-body .btns-group a:last-child {
  color: white;

  display: block;
  text-decoration: none;
}
@media (max-width: 430px) {
  .success-container {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .success-container .success-header {
    font-size: 1.3rem;
  }
  .success-body .btns-group button:first-child {
    font-size: 0.7rem;
  }
  .success-body .btns-group button:last-child {
    font-size: 0.7rem;
  }
  .success-body .img img {
    width: 7rem;
  }
  .success-container > button {
    font-size: 0.7rem;
  }
}
